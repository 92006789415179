import React from "react";
import { Route, Switch } from "react-router-dom";
import "./App.css";
import HomePage from "./pages/homepage/homepage";
import ShopPage from "./pages/shop/shop-page";
import Header from "./components/header/header";
import SignInandSignUpPage from "./pages/sign-in and sign-up/sign-in and sign-up";
import { auth } from "./firebase/firebase.utils";

class  App extends React.Component {
  constructor(){
    super();
    this.state ={
      currentUser: null
    }
  }
  unsubscribeFromAuth = null;
  componentWillMount(){
    this.unsubscribeFromAuth = auth.onAuthStateChanged(user =>{
      this.setState({currentUser: user});
    })
  }
 componentWillUnmount(){
   this.unsubscribeFromAuth();
 }
  render(){
  return (
    <div>
      <Header  currentUser={this.state.currentUser}/>
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route path="/shop" component={ShopPage} />
        <Route path="/signin" component={SignInandSignUpPage} />
      </Switch>
      {/* <HomePage/> */}
    </div>
  );
}
}

export default App;
