import React from "react";
import "./collection-preview.styles.scss";
import CollectionItem from "../collection-item/collection-item";


const CollectionPreview = ({ title, items }) => (
  <div className="collection-preview">
    <h1 className="title">{title.toUpperCase()}</h1>
    <div className="preview">
      {items
      .filter((items,index)=> index <4)
      .map(({id, ...otherProps}) => (
        <CollectionItem key ={id} {...otherProps}/>
      ))}
    </div>
    
  </div>
);
export default CollectionPreview;
