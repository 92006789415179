import firebase  from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';

const config = {
    apiKey: "AIzaSyB41N1dkBjDdPJnS5rMOlWOcgL2k7KAsN8",
    authDomain: "crwn-db-69fb2.firebaseapp.com",
    databaseURL: "https://crwn-db-69fb2.firebaseio.com",
    projectId: "crwn-db-69fb2",
    storageBucket: "crwn-db-69fb2.appspot.com",
    messagingSenderId: "22918176729",
    appId: "1:22918176729:web:2f211cc2f60f892cb3c3c8",
    measurementId: "G-HYTXD79443"
  };
  firebase.initializeApp(config);
  export const auth  = firebase.auth();
  export const firestore = firebase.firestore();


  const provider = new firebase.auth.GoogleAuthProvider();
  provider.setCustomParameters({ prompt:'select_account'});
  export const signInWIthGoogle = () =>auth.signInWithPopup(provider);

  export default firebase;
